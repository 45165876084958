import { default as about1gtj9Ohox2Meta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsHgmqXxUDUdMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93pS6lbGktXCMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleMphlPvCCB1Meta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsclwIod4zGVMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93OHUn8wMQKOMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as vieww7L9vQWCDHMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexnE0w1s78idMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93d8v6vsy8GaMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexoQDuS8KhwFMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93F6Wpyj7H32Meta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index5Nsa9OLnvoMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsXi09F0KZ9sMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings6tWuz1WzxUMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsnQFvHlGxCmMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93TgK1BMNf2xMeta } from "/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexiRrKPuXna0Meta } from "/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmjPul4LnhSDMeta } from "/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmLT0tC1I1OhMeta } from "/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendartnro66U189Meta } from "/tmp/build_75c96c70/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatpnMbZmJ4gaMeta } from "/tmp/build_75c96c70/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutDsLuQMIejMMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedyDeY3ZJEQdMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersJDHEGuPBYeMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93OslAeT71vcMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45edit5suQvwFbGUMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_93tFz2yCDfo2Meta } from "/tmp/build_75c96c70/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communitycxrzRiR5lEMeta } from "/tmp/build_75c96c70/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93aDrqUjWvr3Meta } from "/tmp/build_75c96c70/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93gBlHVkbynCMeta } from "/tmp/build_75c96c70/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardtk4ClcsX0BMeta } from "/tmp/build_75c96c70/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexh6YDZNZtdgMeta } from "/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutIwTW2GOcRdMeta } from "/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45product1otE8E9stPMeta } from "/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsrxYW9d4khwMeta } from "/tmp/build_75c96c70/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93Ds0Hc8NTSCMeta } from "/tmp/build_75c96c70/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexGDDaYPtoEDMeta } from "/tmp/build_75c96c70/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93DjafKF1WCEMeta } from "/tmp/build_75c96c70/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93RsodaNmyCCMeta } from "/tmp/build_75c96c70/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexD8w21PTGzZMeta } from "/tmp/build_75c96c70/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93nxcHevCg8gMeta } from "/tmp/build_75c96c70/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinEWXkKOhgI0Meta } from "/tmp/build_75c96c70/pages/[studio_url]/join.vue?macro=true";
import { default as loginGbuqUIzwbbMeta } from "/tmp/build_75c96c70/pages/[studio_url]/login.vue?macro=true";
import { default as logoutbdn3Ph0GtmMeta } from "/tmp/build_75c96c70/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93nMwC3fgvgkMeta } from "/tmp/build_75c96c70/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93wBCNXDdzisMeta } from "/tmp/build_75c96c70/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93eYcJkTmYa2Meta } from "/tmp/build_75c96c70/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesRuKSLEHV9nMeta } from "/tmp/build_75c96c70/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosGRxylpZUBlMeta } from "/tmp/build_75c96c70/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinrJVxSv37xhMeta } from "/tmp/build_75c96c70/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_933h5tngPFzQMeta } from "/tmp/build_75c96c70/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexEoJhKkY6AoMeta } from "/tmp/build_75c96c70/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indextYOCxuSc36Meta } from "/tmp/build_75c96c70/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as abouttRUjRBHKvAMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as eventsZgeM1WSfBpMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_936BMK0wc7bJMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleHIeB2hBrHCMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsmduMMeBKEnMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_935JEAfuOxlnMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewCG5ZOz25oBMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexL8IwIpiywJMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_930k1EV8oyEzMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexJD4ySTAxAoMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93UgXCPhFNPoMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexFBQHcAE5RWMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionseswgf9eqYgMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsUU57h1nt2FMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsRNqUTwdI5YMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93AvwvnDoENFMeta } from "/tmp/build_75c96c70/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as index30GG6gjte3Meta } from "/tmp/build_75c96c70/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexVwYswRyMJDMeta } from "/tmp/build_75c96c70/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchases1BmGd5yxYnMeta } from "/tmp/build_75c96c70/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionslxpwC0UTR5Meta } from "/tmp/build_75c96c70/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93yCL6JSiIW0Meta } from "/tmp/build_75c96c70/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93SsuI3zo9GwMeta } from "/tmp/build_75c96c70/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetKxd0332ROpMeta } from "/tmp/build_75c96c70/pages/[studio_url]/reset.vue?macro=true";
import { default as shop5IHCQ27JwjMeta } from "/tmp/build_75c96c70/pages/[studio_url]/shop.vue?macro=true";
import { default as signupIqWdxBwUolMeta } from "/tmp/build_75c96c70/pages/[studio_url]/signup.vue?macro=true";
import { default as liveyCoOzfixzaMeta } from "/tmp/build_75c96c70/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsujIslxhJwGMeta } from "/tmp/build_75c96c70/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93OxgH82cu03Meta } from "/tmp/build_75c96c70/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactMS90OBNzcvMeta } from "/tmp/build_75c96c70/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullHEwdmUzOzxMeta } from "/tmp/build_75c96c70/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93TgK1BMNf2xMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93TgK1BMNf2xMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93TgK1BMNf2xMeta || {},
    alias: _91product_id_93TgK1BMNf2xMeta?.alias || [],
    redirect: _91product_id_93TgK1BMNf2xMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: about1gtj9Ohox2Meta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: about1gtj9Ohox2Meta?.path ?? "about",
    meta: about1gtj9Ohox2Meta || {},
    alias: about1gtj9Ohox2Meta?.alias || [],
    redirect: about1gtj9Ohox2Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsHgmqXxUDUdMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsHgmqXxUDUdMeta?.path ?? "events",
    meta: eventsHgmqXxUDUdMeta || {},
    alias: eventsHgmqXxUDUdMeta?.alias || [],
    redirect: eventsHgmqXxUDUdMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93pS6lbGktXCMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93pS6lbGktXCMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93pS6lbGktXCMeta || {},
    alias: _91media_id_93pS6lbGktXCMeta?.alias || [],
    redirect: _91media_id_93pS6lbGktXCMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleMphlPvCCB1Meta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleMphlPvCCB1Meta?.path ?? "free-sample",
    meta: free_45sampleMphlPvCCB1Meta || {},
    alias: free_45sampleMphlPvCCB1Meta?.alias || [],
    redirect: free_45sampleMphlPvCCB1Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsclwIod4zGVMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsclwIod4zGVMeta?.path ?? "included-products",
    meta: included_45productsclwIod4zGVMeta || {},
    alias: included_45productsclwIod4zGVMeta?.alias || [],
    redirect: included_45productsclwIod4zGVMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93OHUn8wMQKOMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93OHUn8wMQKOMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93OHUn8wMQKOMeta || {},
    alias: _91media_id_93OHUn8wMQKOMeta?.alias || [],
    redirect: _91media_id_93OHUn8wMQKOMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: vieww7L9vQWCDHMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: vieww7L9vQWCDHMeta?.path ?? "lessons/:lesson_id()/view",
    meta: vieww7L9vQWCDHMeta || {},
    alias: vieww7L9vQWCDHMeta?.alias || [],
    redirect: vieww7L9vQWCDHMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexnE0w1s78idMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexnE0w1s78idMeta?.path ?? "lessons",
    meta: indexnE0w1s78idMeta || {},
    alias: indexnE0w1s78idMeta?.alias || [],
    redirect: indexnE0w1s78idMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93d8v6vsy8GaMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93d8v6vsy8GaMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93d8v6vsy8GaMeta || {},
    alias: _91media_id_93d8v6vsy8GaMeta?.alias || [],
    redirect: _91media_id_93d8v6vsy8GaMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexoQDuS8KhwFMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexoQDuS8KhwFMeta?.path ?? "playlists/:playlist_id()",
    meta: indexoQDuS8KhwFMeta || {},
    alias: indexoQDuS8KhwFMeta?.alias || [],
    redirect: indexoQDuS8KhwFMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93F6Wpyj7H32Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93F6Wpyj7H32Meta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93F6Wpyj7H32Meta || {},
    alias: _91media_id_93F6Wpyj7H32Meta?.alias || [],
    redirect: _91media_id_93F6Wpyj7H32Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index5Nsa9OLnvoMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: index5Nsa9OLnvoMeta?.path ?? "playlists",
    meta: index5Nsa9OLnvoMeta || {},
    alias: index5Nsa9OLnvoMeta?.alias || [],
    redirect: index5Nsa9OLnvoMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsXi09F0KZ9sMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsXi09F0KZ9sMeta?.path ?? "private-sessions",
    meta: private_45sessionsXi09F0KZ9sMeta || {},
    alias: private_45sessionsXi09F0KZ9sMeta?.alias || [],
    redirect: private_45sessionsXi09F0KZ9sMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings6tWuz1WzxUMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings6tWuz1WzxUMeta?.path ?? "recordings",
    meta: recordings6tWuz1WzxUMeta || {},
    alias: recordings6tWuz1WzxUMeta?.alias || [],
    redirect: recordings6tWuz1WzxUMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsnQFvHlGxCmMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsnQFvHlGxCmMeta?.path ?? "reviews",
    meta: reviewsnQFvHlGxCmMeta || {},
    alias: reviewsnQFvHlGxCmMeta?.alias || [],
    redirect: reviewsnQFvHlGxCmMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexiRrKPuXna0Meta?.name ?? "buy-product-product_id",
    path: indexiRrKPuXna0Meta?.path ?? "/buy/product/:product_id()",
    meta: indexiRrKPuXna0Meta || {},
    alias: indexiRrKPuXna0Meta?.alias || [],
    redirect: indexiRrKPuXna0Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmjPul4LnhSDMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmjPul4LnhSDMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmjPul4LnhSDMeta || {},
    alias: confirmjPul4LnhSDMeta?.alias || [],
    redirect: confirmjPul4LnhSDMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmLT0tC1I1OhMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmLT0tC1I1OhMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmLT0tC1I1OhMeta || {},
    alias: confirmLT0tC1I1OhMeta?.alias || [],
    redirect: confirmLT0tC1I1OhMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendartnro66U189Meta?.name ?? "calendar",
    path: calendartnro66U189Meta?.path ?? "/calendar",
    meta: calendartnro66U189Meta || {},
    alias: calendartnro66U189Meta?.alias || [],
    redirect: calendartnro66U189Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatpnMbZmJ4gaMeta?.name ?? "chat",
    path: chatpnMbZmJ4gaMeta?.path ?? "/chat",
    meta: chatpnMbZmJ4gaMeta || {},
    alias: chatpnMbZmJ4gaMeta?.alias || [],
    redirect: chatpnMbZmJ4gaMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93OslAeT71vcMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93OslAeT71vcMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93OslAeT71vcMeta || {},
    alias: _91group_id_93OslAeT71vcMeta?.alias || [],
    redirect: _91group_id_93OslAeT71vcMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutDsLuQMIejMMeta?.name ?? "community-groups-group_id-about",
    path: aboutDsLuQMIejMMeta?.path ?? "about",
    meta: aboutDsLuQMIejMMeta || {},
    alias: aboutDsLuQMIejMMeta?.alias || [],
    redirect: aboutDsLuQMIejMMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedyDeY3ZJEQdMeta?.name ?? "community-groups-group_id-feed",
    path: feedyDeY3ZJEQdMeta?.path ?? "feed",
    meta: feedyDeY3ZJEQdMeta || {},
    alias: feedyDeY3ZJEQdMeta?.alias || [],
    redirect: feedyDeY3ZJEQdMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersJDHEGuPBYeMeta?.name ?? "community-groups-group_id-members",
    path: membersJDHEGuPBYeMeta?.path ?? "members",
    meta: membersJDHEGuPBYeMeta || {},
    alias: membersJDHEGuPBYeMeta?.alias || [],
    redirect: membersJDHEGuPBYeMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45edit5suQvwFbGUMeta?.name ?? "community-profile-edit",
    path: community_45profile_45edit5suQvwFbGUMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45edit5suQvwFbGUMeta || {},
    alias: community_45profile_45edit5suQvwFbGUMeta?.alias || [],
    redirect: community_45profile_45edit5suQvwFbGUMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tFz2yCDfo2Meta?.name ?? "community-profile-id",
    path: _91id_93tFz2yCDfo2Meta?.path ?? "/community-profile/:id()",
    meta: _91id_93tFz2yCDfo2Meta || {},
    alias: _91id_93tFz2yCDfo2Meta?.alias || [],
    redirect: _91id_93tFz2yCDfo2Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communitycxrzRiR5lEMeta?.name ?? "community",
    path: communitycxrzRiR5lEMeta?.path ?? "/community",
    meta: communitycxrzRiR5lEMeta || {},
    alias: communitycxrzRiR5lEMeta?.alias || [],
    redirect: communitycxrzRiR5lEMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93aDrqUjWvr3Meta?.name ?? "complete-registration-uuid",
    path: _91uuid_93aDrqUjWvr3Meta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93aDrqUjWvr3Meta || {},
    alias: _91uuid_93aDrqUjWvr3Meta?.alias || [],
    redirect: _91uuid_93aDrqUjWvr3Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93gBlHVkbynCMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93gBlHVkbynCMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93gBlHVkbynCMeta || {},
    alias: _91unique_id_93gBlHVkbynCMeta?.alias || [],
    redirect: _91unique_id_93gBlHVkbynCMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardtk4ClcsX0BMeta?.name ?? "dashboard",
    path: dashboardtk4ClcsX0BMeta?.path ?? "/dashboard",
    meta: dashboardtk4ClcsX0BMeta || {},
    alias: dashboardtk4ClcsX0BMeta?.alias || [],
    redirect: dashboardtk4ClcsX0BMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexh6YDZNZtdgMeta?.name ?? "event-details-event_id",
    path: indexh6YDZNZtdgMeta?.path ?? "/event/details/:event_id()",
    meta: indexh6YDZNZtdgMeta || {},
    alias: indexh6YDZNZtdgMeta?.alias || [],
    redirect: indexh6YDZNZtdgMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutIwTW2GOcRdMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutIwTW2GOcRdMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutIwTW2GOcRdMeta || {},
    alias: checkoutIwTW2GOcRdMeta?.alias || [],
    redirect: checkoutIwTW2GOcRdMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45product1otE8E9stPMeta?.name ?? "event-details-event_id-select-product",
    path: select_45product1otE8E9stPMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45product1otE8E9stPMeta || {},
    alias: select_45product1otE8E9stPMeta?.alias || [],
    redirect: select_45product1otE8E9stPMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsrxYW9d4khwMeta?.name ?? "events",
    path: eventsrxYW9d4khwMeta?.path ?? "/events",
    meta: eventsrxYW9d4khwMeta || {},
    alias: eventsrxYW9d4khwMeta?.alias || [],
    redirect: eventsrxYW9d4khwMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93Ds0Hc8NTSCMeta?.name ?? "gifts-uuid",
    path: _91uuid_93Ds0Hc8NTSCMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93Ds0Hc8NTSCMeta || {},
    alias: _91uuid_93Ds0Hc8NTSCMeta?.alias || [],
    redirect: _91uuid_93Ds0Hc8NTSCMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexGDDaYPtoEDMeta?.name ?? "gifts",
    path: indexGDDaYPtoEDMeta?.path ?? "/gifts",
    meta: indexGDDaYPtoEDMeta || {},
    alias: indexGDDaYPtoEDMeta?.alias || [],
    redirect: indexGDDaYPtoEDMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93DjafKF1WCEMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93DjafKF1WCEMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93DjafKF1WCEMeta || {},
    alias: _91invitation_id_93DjafKF1WCEMeta?.alias || [],
    redirect: _91invitation_id_93DjafKF1WCEMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93RsodaNmyCCMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93RsodaNmyCCMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93RsodaNmyCCMeta || {},
    alias: _91product_id_93RsodaNmyCCMeta?.alias || [],
    redirect: _91product_id_93RsodaNmyCCMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexD8w21PTGzZMeta?.name ?? "index",
    path: indexD8w21PTGzZMeta?.path ?? "/",
    meta: indexD8w21PTGzZMeta || {},
    alias: indexD8w21PTGzZMeta?.alias || [],
    redirect: indexD8w21PTGzZMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93nxcHevCg8gMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93nxcHevCg8gMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93nxcHevCg8gMeta || {},
    alias: _91invitation_unique_id_93nxcHevCg8gMeta?.alias || [],
    redirect: _91invitation_unique_id_93nxcHevCg8gMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinEWXkKOhgI0Meta?.name ?? "join",
    path: joinEWXkKOhgI0Meta?.path ?? "/join",
    meta: joinEWXkKOhgI0Meta || {},
    alias: joinEWXkKOhgI0Meta?.alias || [],
    redirect: joinEWXkKOhgI0Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginGbuqUIzwbbMeta?.name ?? "login",
    path: loginGbuqUIzwbbMeta?.path ?? "/login",
    meta: loginGbuqUIzwbbMeta || {},
    alias: loginGbuqUIzwbbMeta?.alias || [],
    redirect: loginGbuqUIzwbbMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutbdn3Ph0GtmMeta?.name ?? "logout",
    path: logoutbdn3Ph0GtmMeta?.path ?? "/logout",
    meta: logoutbdn3Ph0GtmMeta || {},
    alias: logoutbdn3Ph0GtmMeta?.alias || [],
    redirect: logoutbdn3Ph0GtmMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93nMwC3fgvgkMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93nMwC3fgvgkMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93nMwC3fgvgkMeta || {},
    alias: _91meeting_type_slug_93nMwC3fgvgkMeta?.alias || [],
    redirect: _91meeting_type_slug_93nMwC3fgvgkMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93wBCNXDdzisMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93wBCNXDdzisMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93wBCNXDdzisMeta || {},
    alias: _91meeting_unique_id_93wBCNXDdzisMeta?.alias || [],
    redirect: _91meeting_unique_id_93wBCNXDdzisMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93eYcJkTmYa2Meta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93eYcJkTmYa2Meta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93eYcJkTmYa2Meta || {},
    alias: _91meeting_unique_id_93eYcJkTmYa2Meta?.alias || [],
    redirect: _91meeting_unique_id_93eYcJkTmYa2Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesRuKSLEHV9nMeta?.name ?? "my-favorites",
    path: my_45favoritesRuKSLEHV9nMeta?.path ?? "/my-favorites",
    meta: my_45favoritesRuKSLEHV9nMeta || {},
    alias: my_45favoritesRuKSLEHV9nMeta?.alias || [],
    redirect: my_45favoritesRuKSLEHV9nMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosGRxylpZUBlMeta?.name ?? "my-studios",
    path: my_45studiosGRxylpZUBlMeta?.path ?? "/my-studios",
    meta: my_45studiosGRxylpZUBlMeta || {},
    alias: my_45studiosGRxylpZUBlMeta?.alias || [],
    redirect: my_45studiosGRxylpZUBlMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinrJVxSv37xhMeta?.name ?? "optin",
    path: optinrJVxSv37xhMeta?.path ?? "/optin",
    meta: optinrJVxSv37xhMeta || {},
    alias: optinrJVxSv37xhMeta?.alias || [],
    redirect: optinrJVxSv37xhMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_933h5tngPFzQMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_933h5tngPFzQMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_933h5tngPFzQMeta || {},
    alias: _91episode_id_933h5tngPFzQMeta?.alias || [],
    redirect: _91episode_id_933h5tngPFzQMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexEoJhKkY6AoMeta?.name ?? "podcasts-podcast_id",
    path: indexEoJhKkY6AoMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexEoJhKkY6AoMeta || {},
    alias: indexEoJhKkY6AoMeta?.alias || [],
    redirect: indexEoJhKkY6AoMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextYOCxuSc36Meta?.name ?? "podcasts",
    path: indextYOCxuSc36Meta?.path ?? "/podcasts",
    meta: indextYOCxuSc36Meta || {},
    alias: indextYOCxuSc36Meta?.alias || [],
    redirect: indextYOCxuSc36Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93AvwvnDoENFMeta?.name ?? "product-product_id",
    path: _91product_id_93AvwvnDoENFMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93AvwvnDoENFMeta || {},
    alias: _91product_id_93AvwvnDoENFMeta?.alias || [],
    redirect: _91product_id_93AvwvnDoENFMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: abouttRUjRBHKvAMeta?.name ?? "product-product_id-about",
    path: abouttRUjRBHKvAMeta?.path ?? "about",
    meta: abouttRUjRBHKvAMeta || {},
    alias: abouttRUjRBHKvAMeta?.alias || [],
    redirect: abouttRUjRBHKvAMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsZgeM1WSfBpMeta?.name ?? "product-product_id-events",
    path: eventsZgeM1WSfBpMeta?.path ?? "events",
    meta: eventsZgeM1WSfBpMeta || {},
    alias: eventsZgeM1WSfBpMeta?.alias || [],
    redirect: eventsZgeM1WSfBpMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_936BMK0wc7bJMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_936BMK0wc7bJMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_936BMK0wc7bJMeta || {},
    alias: _91media_id_936BMK0wc7bJMeta?.alias || [],
    redirect: _91media_id_936BMK0wc7bJMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleHIeB2hBrHCMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleHIeB2hBrHCMeta?.path ?? "free-sample",
    meta: free_45sampleHIeB2hBrHCMeta || {},
    alias: free_45sampleHIeB2hBrHCMeta?.alias || [],
    redirect: free_45sampleHIeB2hBrHCMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsmduMMeBKEnMeta?.name ?? "product-product_id-included-products",
    path: included_45productsmduMMeBKEnMeta?.path ?? "included-products",
    meta: included_45productsmduMMeBKEnMeta || {},
    alias: included_45productsmduMMeBKEnMeta?.alias || [],
    redirect: included_45productsmduMMeBKEnMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_935JEAfuOxlnMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_935JEAfuOxlnMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_935JEAfuOxlnMeta || {},
    alias: _91media_id_935JEAfuOxlnMeta?.alias || [],
    redirect: _91media_id_935JEAfuOxlnMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewCG5ZOz25oBMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewCG5ZOz25oBMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewCG5ZOz25oBMeta || {},
    alias: viewCG5ZOz25oBMeta?.alias || [],
    redirect: viewCG5ZOz25oBMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexL8IwIpiywJMeta?.name ?? "product-product_id-lessons",
    path: indexL8IwIpiywJMeta?.path ?? "lessons",
    meta: indexL8IwIpiywJMeta || {},
    alias: indexL8IwIpiywJMeta?.alias || [],
    redirect: indexL8IwIpiywJMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_930k1EV8oyEzMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_930k1EV8oyEzMeta?.path ?? "media/:media_id()",
    meta: _91media_id_930k1EV8oyEzMeta || {},
    alias: _91media_id_930k1EV8oyEzMeta?.alias || [],
    redirect: _91media_id_930k1EV8oyEzMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexJD4ySTAxAoMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexJD4ySTAxAoMeta?.path ?? "playlists/:playlist_id()",
    meta: indexJD4ySTAxAoMeta || {},
    alias: indexJD4ySTAxAoMeta?.alias || [],
    redirect: indexJD4ySTAxAoMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93UgXCPhFNPoMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93UgXCPhFNPoMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93UgXCPhFNPoMeta || {},
    alias: _91media_id_93UgXCPhFNPoMeta?.alias || [],
    redirect: _91media_id_93UgXCPhFNPoMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexFBQHcAE5RWMeta?.name ?? "product-product_id-playlists",
    path: indexFBQHcAE5RWMeta?.path ?? "playlists",
    meta: indexFBQHcAE5RWMeta || {},
    alias: indexFBQHcAE5RWMeta?.alias || [],
    redirect: indexFBQHcAE5RWMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionseswgf9eqYgMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionseswgf9eqYgMeta?.path ?? "private-sessions",
    meta: private_45sessionseswgf9eqYgMeta || {},
    alias: private_45sessionseswgf9eqYgMeta?.alias || [],
    redirect: private_45sessionseswgf9eqYgMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsUU57h1nt2FMeta?.name ?? "product-product_id-recordings",
    path: recordingsUU57h1nt2FMeta?.path ?? "recordings",
    meta: recordingsUU57h1nt2FMeta || {},
    alias: recordingsUU57h1nt2FMeta?.alias || [],
    redirect: recordingsUU57h1nt2FMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsRNqUTwdI5YMeta?.name ?? "product-product_id-reviews",
    path: reviewsRNqUTwdI5YMeta?.path ?? "reviews",
    meta: reviewsRNqUTwdI5YMeta || {},
    alias: reviewsRNqUTwdI5YMeta?.alias || [],
    redirect: reviewsRNqUTwdI5YMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index30GG6gjte3Meta?.name ?? "product",
    path: index30GG6gjte3Meta?.path ?? "/product",
    meta: index30GG6gjte3Meta || {},
    alias: index30GG6gjte3Meta?.alias || [],
    redirect: index30GG6gjte3Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwYswRyMJDMeta?.name ?? "profile",
    path: indexVwYswRyMJDMeta?.path ?? "/profile",
    meta: indexVwYswRyMJDMeta || {},
    alias: indexVwYswRyMJDMeta?.alias || [],
    redirect: indexVwYswRyMJDMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchases1BmGd5yxYnMeta?.name ?? "profile-purchases",
    path: purchases1BmGd5yxYnMeta?.path ?? "/profile/purchases",
    meta: purchases1BmGd5yxYnMeta || {},
    alias: purchases1BmGd5yxYnMeta?.alias || [],
    redirect: purchases1BmGd5yxYnMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionslxpwC0UTR5Meta?.name ?? "profile-redemptions",
    path: redemptionslxpwC0UTR5Meta?.path ?? "/profile/redemptions",
    meta: redemptionslxpwC0UTR5Meta || {},
    alias: redemptionslxpwC0UTR5Meta?.alias || [],
    redirect: redemptionslxpwC0UTR5Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93yCL6JSiIW0Meta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93yCL6JSiIW0Meta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93yCL6JSiIW0Meta || {},
    alias: _91quiz_id_93yCL6JSiIW0Meta?.alias || [],
    redirect: _91quiz_id_93yCL6JSiIW0Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93SsuI3zo9GwMeta?.name ?? "reset-password-uid-token",
    path: _91token_93SsuI3zo9GwMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93SsuI3zo9GwMeta || {},
    alias: _91token_93SsuI3zo9GwMeta?.alias || [],
    redirect: _91token_93SsuI3zo9GwMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetKxd0332ROpMeta?.name ?? "reset",
    path: resetKxd0332ROpMeta?.path ?? "/reset",
    meta: resetKxd0332ROpMeta || {},
    alias: resetKxd0332ROpMeta?.alias || [],
    redirect: resetKxd0332ROpMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shop5IHCQ27JwjMeta?.name ?? "shop",
    path: shop5IHCQ27JwjMeta?.path ?? "/shop",
    meta: shop5IHCQ27JwjMeta || {},
    alias: shop5IHCQ27JwjMeta?.alias || [],
    redirect: shop5IHCQ27JwjMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupIqWdxBwUolMeta?.name ?? "signup",
    path: signupIqWdxBwUolMeta?.path ?? "/signup",
    meta: signupIqWdxBwUolMeta || {},
    alias: signupIqWdxBwUolMeta?.alias || [],
    redirect: signupIqWdxBwUolMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveyCoOzfixzaMeta?.name ?? "streaming-event_id-live",
    path: liveyCoOzfixzaMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveyCoOzfixzaMeta || {},
    alias: liveyCoOzfixzaMeta?.alias || [],
    redirect: liveyCoOzfixzaMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsujIslxhJwGMeta?.name ?? "tips",
    path: tipsujIslxhJwGMeta?.path ?? "/tips",
    meta: tipsujIslxhJwGMeta || {},
    alias: tipsujIslxhJwGMeta?.alias || [],
    redirect: tipsujIslxhJwGMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93OxgH82cu03Meta?.name ?? "waiver-uuid",
    path: _91uuid_93OxgH82cu03Meta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93OxgH82cu03Meta || {},
    alias: _91uuid_93OxgH82cu03Meta?.alias || [],
    redirect: _91uuid_93OxgH82cu03Meta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactMS90OBNzcvMeta?.name ?? "widgets-calendars-compact",
    path: compactMS90OBNzcvMeta?.path ?? "/widgets/calendars/compact",
    meta: compactMS90OBNzcvMeta || {},
    alias: compactMS90OBNzcvMeta?.alias || [],
    redirect: compactMS90OBNzcvMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullHEwdmUzOzxMeta?.name ?? "widgets-calendars-full",
    path: fullHEwdmUzOzxMeta?.path ?? "/widgets/calendars/full",
    meta: fullHEwdmUzOzxMeta || {},
    alias: fullHEwdmUzOzxMeta?.alias || [],
    redirect: fullHEwdmUzOzxMeta?.redirect,
    component: () => import("/tmp/build_75c96c70/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]